import request from '@/utils/request'

//设置配置
export function set_wxconf (data) {
    return request({
        url: '/appletcode/set_wxconf',
        method: 'post',
        data
    });
}
//提交代码
export function upload_wxcode (data) {
    return request({
        url: '/appletcode/upload_wxcode',
        method: 'post',
        data
    });
}
//提交审核
export function upload_approve (data) {
    return request({
        url: '/appletcode/upload_approve',
        method: 'post',
        data
    });
}
//获取审核状态
export function get_wxapprovestatus (data) {
    return request({
        url: 'appletcode/get_wxapprovestatus',
        method: 'get',
        params: data
    })
}
//审核记录
export function approve_record (data) {
    return request({
        url: 'appletcode/approve_record',
        method: 'get',
        params: data
    })
}
//版本记录
export function get_wxversionlist (data) {
    return request({
        url: 'appletcode/get_wxversionlist',
        method: 'get',
        params: data
    })
}
//发布小程序
export function releax_code (data) {
    return request({
        url: '/appletcode/releax_code',
        method: 'post',
        data
    });
}
//设置协议
export function upload_private (data) {
    return request({
        url: '/appletcode/upload_private',
        method: 'post',
        data
    });
}
//获取获取微信配置
export function get_wxconf (data) {
    return request({
        url: 'appletcode/get_wxconf',
        method: 'get',
        params: data
    })
} 
//获取分类
export function get_reloadwxcate (data) {
    return request({
        url: 'appletcode/get_reloadwxcate',
        method: 'get',
        params: data
    })
}
//获取授权状态
export function get_authstatus (data) {
    return request({
        url: 'appletcode/get_authstatus',
        method: 'get',
        params: data
    })
}
//绑定授权
export function wxBindAuth (data) {
    return request({
        url: '/appletcode/wx_bind',
        method: 'post',
        data
    });
}
//合同短信充值
export function cs_chargepay (data) {
    return request({
        url: '/setting/cs_chargepay',
        method: 'post',
        data
    });
}
//短信合同充值价格列表
export function get_smscpricelist (data) {
    return request({
        url: 'setting/get_smscpricelist',
        method: 'get',
        params: data
    })
}
// 获取订单支付状态
export function get_orderstatus (data) {
    return request({
        url: 'setting/get_orderstatus',
        method: 'get',
        params: data
    })
}
//订单列表记录
export function get_orderlist (data) {
    return request({
        url: 'setting/get_orderlist',
        method: 'get',
        params: data
    })
}
//获取模板签名列表
export function get_list (data) {
    return request({
        url: 'application/get_list',
        method: 'get',
        params: data
    })
}
//模板签名申请
export function update_signtemplate (data) {
    return request({
        url: '/application/update_signtemplate',
        method: 'post',
        data
    });
}
//支付订单
export function pay_order (data) {
    return request({
        url: '/application/pay_order',
        method: 'post',
        data
    });
}
//使用短信 签名 模板
export function update_status (data) {
    return request({
        url: '/application/update_status',
        method: 'post',
        data
    });
}
// 应用中心菜单菜单
export function get_appcenterlist (data) {
    return request({
        url: 'application/get_appcenterlist',
        method: 'get',
        params: data
    })
}
// 支付菜单刷新
export function get_menulist (data) {
    return request({
        url: 'application/get_menulist',
        method: 'get',
        params: data
    })
}
//更新小程序域名配置项
export function update_domainconfig (data) {
    return request({
        url: '/appletcode/update_domainconfig',
        method: 'post',
        data
    });
}
//权限分类
export function get_menuclass (data) {
    return request({
        url: 'application/get_menuclass',
        method: 'get',
        params: data
    })
}
//取消独立域名使用状态
export function cancel_use (data) {
    return request({
        url: '/application/cancel_use',
        method: 'post',
        data
    });
}


