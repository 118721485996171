<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>

export default {
  name: "app",
};
</script>

<style>
@import "assets/css/iconfont.css";
@import "assets/css/allUse.css";
</style>
